import { LoginStatusType, LoginStatusTypes } from '../types/loginStatusType'

import { track } from './analyticsUtils'

const fetchAccount = async () => {
  const appUrl = process.env.GATSBY_GONFALON_URL + '/internal/account/session'
  const application = 'launchDarkly'
  try {
    const result = await fetch(appUrl, {
      credentials: 'include',
    })
    const account = await result.json()

    if (result.status === 200) {
      // only fetch member if account is logged in
      const user = await fetchMember()
      return {
        lastUpdated: Date.now(),
        userId: user?.userId,
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
        accountId: account.accountId,
        status: LoginStatusTypes.LOGGED_IN,
        application,
        creationDate: user?.creationDate,
        quickstartStatus: user?.quickstartStatus,
        lastSeen: user?.lastSeen,
        isBeta: user?.isBeta,
      }
    }
  } catch (error) {
    return {
      lastUpdated: Date.now(),
      accountId: '',
      status: LoginStatusTypes.LOGGED_OUT,
      application,
    }
  }
  return {
    lastUpdated: Date.now(),
    accountId: '',
    status: LoginStatusTypes.LOGGED_OUT,
    application,
  }
}

const fetchMember = async () => {
  const appUrl = process.env.GATSBY_GONFALON_URL + '/api/v2/members/me'
  try {
    const result = await fetch(appUrl, {
      credentials: 'include',
    })
    if (result.status === 200) {
      const member = await result.json()
      return {
        userId: member._id,
        email: member.email,
        firstName: member.firstName,
        lastName: member.lastName,
        creationDate: member.creationDate,
        quickstartStatus: member.quickstartStatus,
        lastSeen: member._lastSeen,
        isBeta: member.isBeta,
      }
    }
    return null
  } catch (error) {
    return null
  }
}

export const accountLogout = async () => {
  const appUrl = process.env.GATSBY_GONFALON_URL + '/internal/account/session'
  try {
    const result = await fetch(appUrl, {
      credentials: 'include',
      method: 'DELETE',
    })
    if (result.status === 204) {
      return true
    }
  } catch (error) {
    return false
  }
  return false
}

export const fetchAndSetLoginStatus = async () => {
  const loginStatus = await fetchAccount()
  localStorage.setItem('loginStatus', JSON.stringify(loginStatus as LoginStatusType))
  track('login status', { loginStatus })
}
