import { Button } from '@launchpad-ui/core'

import useLoginStatus from '../hooks/useLoginStatus'
import { LoginStatusTypes } from '../types/loginStatusType'
import { useIsEU, useIsFederal } from '../utils/siteAwareUtils'

import SearchRoot from './search/searchRoot'
import Hamburger from './sideNav/hamburger'
import Icon from './icon'
import Link from './link'

const Header = () => {
  const isFederal = useIsFederal()
  const isEU = useIsEU()

  const [loginStatus] = useLoginStatus()
  const isLoggedOut = loginStatus?.status === LoginStatusTypes.LOGGED_OUT

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleClick = (e: any) => {
    e.preventDefault()
    if (isFederal) {
      window.location.href = 'https://app.launchdarkly.us'
    } else if (isEU) {
      window.location.href = 'https://app.eu.launchdarkly.com'
    } else {
      window.location.href = 'https://app.launchdarkly.com'
    }
  }

  return (
    <header
      sx={{
        bg: 'accent',
        color: 'grayscaleWhite',
        height: '4.5rem',
        width: '100%',
        position: 'fixed',
        top: '0',
        zIndex: 10,
        paddingRight: '1.5rem',
      }}
    >
      <div
        sx={{
          display: 'grid',
          gridTemplateColumns: ['100%', '18rem auto', '18rem 48rem auto'],
          minHeight: '100%',
          gridTemplateAreas: [
            `
            'mobile'
            `,
            `
            'brand nav'
            `,
            `
            'brand nav nav'
            `,
          ],
        }}
      >
        <span sx={{ gridArea: 'brand', display: ['none', 'flex'], alignItems: 'center', ml: [4, 6] }}>
          <Link
            to="/"
            aria-label="Go to the LaunchDarkly documentation homepage"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Icon name="header-logo" variant="header" />
            {isFederal && <Icon name="federal-tag" variant="header" sx={{ height: [null, '1.2rem'], ml: 3 }} />}
            {isEU && <Icon name="eu-tag" variant="header" sx={{ height: [null, '1.2rem'], ml: 3 }} />}
          </Link>
        </span>
        <span
          sx={{
            gridArea: ['mobile', 'nav'],
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Link to="/" sx={{ mx: 4, display: ['flex', 'none'], flexDirection: 'column', alignItems: 'center' }}>
            <Icon
              name="refresh2024/osmo"
              variant="header"
              sx={{ height: 4, width: '1.4rem', path: { fill: 'rgb(232, 233, 238)' } }}
            />
            {isFederal && <Icon name="federal-tag" variant="header" sx={{ height: '1.2rem' }} />}
          </Link>
          <div
            sx={{
              gridArea: ['nav'],
              display: 'flex',
              alignItems: 'right',
              width: ['100%', '100%', '25%'],
              mx: [0, 4],
              ml: [0, 0, 4],
            }}
          >
            <SearchRoot />
          </div>
          <div sx={{ marginLeft: ['1rem', 0, 0], display: ['none', 'flex', 'flex', 'flex'], alignItems: 'center' }}>
            <Button kind="primary" onClick={handleClick}>
              {' '}
              {isLoggedOut ? 'Sign in to LaunchDarkly' : 'Go to LaunchDarkly'}
            </Button>
          </div>
          <span sx={{ display: ['flex', 'none'], mx: 4 }}>
            <Hamburger />
          </span>
        </span>
      </div>
    </header>
  )
}

export default Header
