import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum-slim'
import { getLCP, Metric } from 'web-vitals'

const LCP_SLO_TARGET = 4000
const GIT_SHA = process.env.GATSBY_GIT_SHA?.toString().trim()

const ignoredErrorStrings = [418, 422, 423, 425].map(num => {
  return `https://reactjs.org/docs/error-decoder.html?invariant=${num}`
})

const initDatadogRum = () => {
  datadogRum.init({
    applicationId: process.env.GATSBY_DATADOG_RUM_APPLICATION_ID,
    clientToken: process.env.GATSBY_DATADOG_RUM_CLIENT_TOKEN,
    env: process.env.GATSBY_ACTIVE_ENV,
    site: 'datadoghq.com',
    service: 'Docs',
    sessionSampleRate: 100,
    trackUserInteractions: true,
    ...(GIT_SHA && { version: GIT_SHA }),

    beforeSend: event => {
      if (
        event.type === 'error' &&
        event?.error?.message !== undefined &&
        ignoredErrorStrings.some(v => event.error.message.includes(v))
      ) {
        return false
      }
    },
  })
}

const sendLCPToDatadog = (metric: Metric) => {
  const sloLogger = datadogLogs.getLogger('sloLogger')
  const withinSlo = metric.value <= LCP_SLO_TARGET
  const logMessage = {
    custom: {
      measures: {
        ['largest_contentful_paint']: metric.value,
        ['largest_contentful_paint_within_slo']: withinSlo,
      },
    },
  }
  sloLogger.info('LCP recorded from docs', logMessage)
}

const logLCP = () => {
  datadogLogs.init({
    clientToken: process.env.GATSBY_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    env: process.env.GATSBY_ACTIVE_ENV,
    forwardErrorsToLogs: false,
    ...(GIT_SHA && { version: GIT_SHA }),

    beforeSend: log => {
      if (log.message && ignoredErrorStrings.some(v => log.message.includes(v))) {
        return false
      }
    },
  })

  datadogLogs.addLoggerGlobalContext('service', 'docs')
  datadogLogs.createLogger('sloLogger')
  getLCP(metric => sendLCPToDatadog(metric))
}

export const initDataDogLogging = () => {
  initDatadogRum()
  logLCP()
}
