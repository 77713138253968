import useLocalStorageState from 'use-local-storage-state'

import { LoginStatusType } from '../types/loginStatusType'

const useLoginStatus = () => {
  return useLocalStorageState<LoginStatusType>('loginStatus')
}

export const getLoginStatusFromStorage = () => {
  return JSON.parse(localStorage.getItem('loginStatus')) as LoginStatusType
}

export default useLoginStatus
