import { Suspense } from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'

import SiteSelector from '../siteSelector/siteSelector'

import TreeNode from './treeNode'

const DesktopSideNav = () => {
  const {
    site: { pathPrefix },
    home: { nodes: home },
    gettingStarted: { nodes: gettingStarted },
    integrations: { nodes: integrations },
    sdk: { nodes: sdk },
    guides: { nodes: guides },
    apiDocs: { nodes: apiDocs },
    homeItems: { nodes: homeItems },
  } = useStaticQuery(graphql`
    query {
      site {
        pathPrefix
      }

      home: allNavigationDataJson(filter: { label: { eq: "Home" } }) {
        nodes {
          label
          path
          svg
        }
      }

      gettingStarted: allNavigationDataJson(filter: { label: { eq: "Get started" } }) {
        nodes {
          label
          path
          svg
          items {
            label
            path
          }
        }
      }

      integrations: allNavigationDataJson(filter: { path: { glob: "/integrations/**" } }) {
        nodes {
          path
          label
          svg
          items {
            label
            path
            items {
              label
              path
              shortLabel
              items {
                label
                path
              }
            }
          }
        }
      }

      sdk: allNavigationDataJson(filter: { path: { glob: "/sdk/**" } }) {
        nodes {
          path
          label
          svg
          items {
            label
            path
            items {
              label
              path
              shortLabel
              items {
                label
                path
              }
            }
          }
        }
      }

      guides: allNavigationDataJson(filter: { path: { glob: "/guides/**" } }) {
        nodes {
          path
          label
          svg
          items {
            label
            path
            svg
            items {
              label
              path
              svg
              shortLabel
              items {
                label
                path
              }
            }
          }
        }
      }

      apiDocs: allNavigationDataJson(filter: { label: { eq: "API docs" } }) {
        nodes {
          path
          label
          svg
        }
      }

      homeItems: allNavigationDataJson(filter: { path: { glob: "/home/**" } }) {
        nodes {
          path
          label
          svg
          items {
            label
            path
            svg
            group
            items {
              label
              path
              svg
              shortLabel
              items {
                label
                path
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Suspense fallback={null}>
      {
        <nav
          sx={{
            bg: theme => theme.colors.navBarGray,
            display: ['none', 'block'],
            top: '4.5rem',
            bottom: 0,
            position: 'fixed',
            overflow: 'auto',
            pt: 4,
            width: '19rem',
          }}
        >
          <div sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <SiteSelector navigateFn={navigate} />

            <div className="subpages">
              <TreeNode pathPrefix={pathPrefix} nodes={home} />
              <TreeNode pathPrefix={pathPrefix} nodes={gettingStarted} />
              <TreeNode pathPrefix={pathPrefix} nodes={guides} />
              <TreeNode pathPrefix={pathPrefix} nodes={sdk} />
              <TreeNode pathPrefix={pathPrefix} nodes={integrations} />
              <TreeNode pathPrefix={pathPrefix} nodes={apiDocs} />
            </div>

            <div sx={{ margin: '0 2rem' }}>
              <hr />
            </div>

            <div className="homeNodeItems">
              <TreeNode pathPrefix={pathPrefix} nodes={homeItems[0].items} />
            </div>
          </div>
        </nav>
      }
    </Suspense>
  )
}

export default DesktopSideNav
