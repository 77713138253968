import { useEffect, useState } from 'react'

import { ApplicationType } from '../../types/applicationType'
import { getGonfalonContext } from '../../utils/secureMode/getGonfalonContext'
import { GonfalonContext } from '../../utils/secureMode/types'

type UseGonfalonContextOptions = {
  application: ApplicationType
  anonymous: boolean
  enabled: boolean
}

export const useGonfalonContext = ({ application, anonymous, enabled }: UseGonfalonContextOptions) => {
  const [context, setContext] = useState<GonfalonContext | null>(null)

  useEffect(() => {
    const abortController = new AbortController()
    const signal = abortController.signal

    const fetchConfig = async () => {
      const response = await getGonfalonContext({ application, anonymous, signal })
      if (response) {
        setContext(response)
      }
    }

    if (enabled) {
      fetchConfig()
    } else {
      setContext({ kind: 'user', key: 'anonymous', anonymous: true, application })
    }

    return () => {
      abortController.abort()
      setContext(null)
    }
  }, [application, anonymous, enabled])

  return { context }
}
