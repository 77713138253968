import { ExpandCollapseEnum, SideNavItem, TreeNodeExpandCollapseState } from './types'

/**
 * Recursively set nodes to be expanded or collapsed.
 * @param nodes
 * @param currentPath
 */
const getInitialState = (nodes: SideNavItem[], currentPath: string): TreeNodeExpandCollapseState => {
  if (!nodes) {
    return {}
  }

  return nodes.reduce((state, node) => {
    const x = getInitialState(node.items, currentPath)
    const shouldBeExpanded = Object.values(x).find(v => v) || currentPath.includes(node.path)
    return {
      ...state,
      ...x,
      [node.path]: shouldBeExpanded ? ExpandCollapseEnum.Expanded : ExpandCollapseEnum.Collapsed,
    }
  }, {})
}

export default getInitialState
