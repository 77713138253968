import { HTMLProps } from 'react'
import { BoxOwnProps, jsx } from 'theme-ui'

export type IconName =
  | 'akamai'
  | 'android'
  | 'apex'
  | 'c'
  | 'cloudflare'
  | 'electron'
  | 'erlang'
  | 'flutter'
  | 'ios'
  | 'javascript'
  | 'node'
  | 'react'
  | 'roku'
  | 'swift'
  | 'erlang'
  | 'go'
  | 'haskell'
  | 'java'
  | 'lua'
  | 'net'
  | 'nodejs'
  | 'php'
  | 'python'
  | 'react'
  | 'roku'
  | 'ruby'
  | 'rust'
  | 'vercel'
  | 'vue'
  | 'alert-rhombus'
  | 'application-plus'
  | 'application-plus-outline'
  | 'arrow-down'
  | 'arrow-right'
  | 'arrow-up'
  | 'article'
  | 'atom'
  | 'book'
  | 'book-code'
  | 'book-outline'
  | 'bullseye-arrow'
  | 'bullseye-arrow'
  | 'chart-area'
  | 'chart-areaspline'
  | 'chart-dashboard'
  | 'chart-venn'
  | 'checkbox-marked-circle'
  | 'chevron-down'
  | 'chevron-right'
  | 'chevron-up'
  | 'compass'
  | 'credit-card'
  | 'data-export'
  | 'dependency'
  | 'diamond-outline'
  | 'discord'
  | 'earth'
  | 'expand-more'
  | 'federal-tag'
  | 'fingerprint'
  | 'flag-code'
  | 'flair-icons'
  | 'flask'
  | 'folders'
  | 'gatsby'
  | 'graduation-cap'
  | 'group-outline'
  | 'header-logo'
  | 'help-circle'
  | 'information'
  | 'lightbulb'
  | 'lightbulb-on-outline'
  | 'link-external'
  | 'link-variant'
  | 'link-variant'
  | 'magic-wand'
  | 'menu'
  | 'network-cloud'
  | 'open-in-new'
  | 'openapi-logo'
  | 'osmo'
  | 'package'
  | 'package-outline'
  | 'pencil'
  | 'people'
  | 'person-gear'
  | 'plug-connect-outline'
  | 'query-stats'
  | 'quick-start'
  | 'remove-circle'
  | 'ruler'
  | 'search'
  | 'security'
  | 'shield-key-outline'
  | 'timeline-text'
  | 'toggle-on'
  | 'toggles-multiple-solid'
  | 'window-close'
  | 'youtube'

export type IconProps = {
  name: IconName | string
  onClick?: () => void
  fill?: string
} & BoxOwnProps &
  HTMLProps<SVGSVGElement>

export default function Icon({ name = 'window-close', className, variant, onClick, ...props }: IconProps) {
  let SVGComponent
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    SVGComponent = require(`../../assets/icons/${name}.svg`).default
  } catch (e) {
    console.error(`${name}.svg does not exist.`, e)
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    SVGComponent = require('../../assets/icons/window-close.svg').default
  }

  return (
    <SVGComponent
      className={className}
      sx={{
        variant: `icons.${variant}`,
        ...props,
        flex: 'none',
      }}
      onClick={onClick}
    />
  )
}
