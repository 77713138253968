import { ApplicationType } from '../../types/applicationType'

import { GonfalonContext } from './types'
import { getBaseUrl } from './utils'

type InitGonfalonSecureModeOptions = {
  application: ApplicationType
  context: GonfalonContext
  signal: AbortSignal
}

export type SecureModeConfig = {
  secureModeHash: string | null
  dogfoodContext: object | null
}

export const initGonfalonSecureMode = async ({
  application,
  context,
  signal,
}: InitGonfalonSecureModeOptions): Promise<SecureModeConfig> => {
  const url = new URL(`${getBaseUrl(application)}/internal/docs/init`)
  const body = JSON.stringify(context)

  try {
    const res = await fetch(url, {
      signal,
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const json = await res.json()
    return json as SecureModeConfig
  } catch (err) {
    if (signal.aborted) {
      return { secureModeHash: null, dogfoodContext: null }
    }
    throw new Error(`Failed to load config: ${err}`)
  }
}
