module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-2d10565ea2/0/cache/gatsby-remark-images-npm-7.10.0-ee368802a4-c13a0c26c6.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":640,"showCaptions":true,"linkImagesToOriginal":true,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-theme-ui-virtual-694f97307c/0/cache/gatsby-plugin-theme-ui-npm-0.15.7-2560555b4e-18900a23e8.zip/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-catch-links-virtual-733484c3da/0/cache/gatsby-plugin-catch-links-npm-5.10.0-65b5a646ff-149ae91ca9.zip/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-610d3d3259/0/cache/gatsby-plugin-manifest-npm-5.10.0-80908bca4d-ad7b5a4f91.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LaunchDarkly Docs","short_name":"LD Docs","start_url":"/","background_color":"#0E1932","theme_color":"#FFF","display":"minimal-ui","icon":"assets/icons/favicon-osmo-prod.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"391157b60e88c60b4c0f9b62f339b1ce"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-gtag-virtual-b424131f0a/0/cache/gatsby-plugin-google-gtag-npm-5.10.0-efc4ef3169-09ea392fca.zip/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-P12DWV2SBZ","G-31EH4XPW51"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-88700e1141/0/cache/gatsby-plugin-google-tagmanager-npm-5.10.0-c73fdc66a4-ed5e806e6e.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M4F7FSC","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-launchdarkly-virtual-6631ec46c6/0/cache/gatsby-plugin-launchdarkly-npm-1.0.0-d42f7a6f59-bf17f2add9.zip/node_modules/gatsby-plugin-launchdarkly/gatsby-browser.js'),
      options: {"plugins":[],"clientSideID":"5866f3891cd8810a42ce5281","options":{"baseUrl":"https://sdk.ld.catamorphic.com","streamUrl":"https://stream.ld.catamorphic.com","eventsUrl":"https://events.ld.catamorphic.com","bootstrap":"localStorage"}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-canonical-urls-virtual-4c36936ea0/0/cache/gatsby-plugin-canonical-urls-npm-5.13.1-b01f445972-fdd98831c9.zip/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://docs.launchdarkly.com","stripQueryString":true},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-segment-js-virtual-9015c63276/0/cache/gatsby-plugin-segment-js-npm-5.0.0-515e10dd60-c3b953d90d.zip/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"RUtph5AIOikMUv9vQUVqHrb6G2pALs0T","devKey":"RUtph5AIOikMUv9vQUVqHrb6G2pALs0T","trackPage":true,"delayLoad":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-57a3b2adcb/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
