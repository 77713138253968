import { ApplicationType } from '../../types/applicationType'

import { GonfalonContext } from './types'
import { getBaseUrl } from './utils'

type GetGonfalonContextOptions = {
  application: ApplicationType
  anonymous: boolean
  signal: AbortSignal
}

const getUrl = (anonymous: boolean, application: ApplicationType) => {
  const base = getBaseUrl(application)

  if (anonymous) {
    return new URL(`${base}/internal/docs/context/anonymous`)
  }

  return new URL(`${base}/internal/docs/context/authenticated`)
}

export const getGonfalonContext = async ({
  anonymous,
  application,
  signal,
}: GetGonfalonContextOptions): Promise<GonfalonContext> => {
  const url = getUrl(anonymous, application)

  try {
    const res = await fetch(url, {
      signal,
      credentials: anonymous ? undefined : 'include',
    })
    const json = await res.json()
    return json as GonfalonContext
  } catch (err) {
    if (signal.aborted) {
      return {} as GonfalonContext
    }
    throw new Error(`Failed to load context: ${err}`)
  }
}
