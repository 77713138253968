// ld:<ID>:<TOKEN> excluding "diagnostics"
const regex = /^ld:[\da-fA-F]+:(?!.*diagnostics).*$/

export function clearHashBasedLocalStorage(cb: () => void) {
  const keys = Object.keys(localStorage)
  keys.forEach(key => {
    if (regex.test(key)) {
      localStorage.removeItem(key)
    }
  })

  cb()
}
