import { useEffect, useMemo } from 'react'
import { useLDClient } from 'gatsby-plugin-launchdarkly'

import useSite from '../../components/siteSelector/useSite'
import { LoginStatusType, LoginStatusTypes } from '../../types/loginStatusType'
import { SiteType } from '../../types/siteType'
import { appendPropertiesToUserContext } from '../../utils/secureMode/utils'
import useLoginStatus, { getLoginStatusFromStorage } from '../useLoginStatus'

import { clearHashBasedLocalStorage } from './clearHashBasedLocalStorage'
import { useGonfalonContext } from './useGonfalonContext'
import { useInitGonfalonSecureMode } from './useInitGonfalonSecureMode'

const enableGonfalonLogin = process.env.GATSBY_ENABLE_GONFALON_LOGIN === 'true'

const isFederal = (site?: SiteType) => {
  return site === 'federal'
}

const isEU = (site?: SiteType) => {
  return site === 'eu'
}

const isCommercialLoggedInUser = (site: SiteType, loginStatus?: LoginStatusType) => {
  return loginStatus?.accountId && !isFederal(site)
}

const isAnonymous = (site: SiteType, loginStatus?: LoginStatusType) => {
  return !isCommercialLoggedInUser(site, loginStatus) && !isFederal(site) && !isLoggedIn(loginStatus)
}

const isLoggedIn = (loginStatus?: LoginStatusType) => loginStatus?.status === LoginStatusTypes.LOGGED_IN

export const useSecureLDClient = () => {
  const [loginStatus, setLoginStatus] = useLoginStatus()
  const [selectedSite] = useSite()
  const ldClient = useLDClient()

  // At this point, we know if the user is logged in or not thats to the
  // usage of `loginAndLogoutUtils` in gatsby-browser.
  // Here, we fetch the context from gonfalon
  const { context: gonfalonContext } = useGonfalonContext({
    application: selectedSite,
    anonymous: isAnonymous(selectedSite, loginStatus),
    enabled: enableGonfalonLogin,
  })

  // Here, we append some custom properties
  // to the user object in the context for
  // backwards compatability with existing docs team targeting rules
  const context = useMemo(() => {
    const properties: { [key: string]: string } = {
      application: selectedSite,
    }

    if (isCommercialLoggedInUser(selectedSite, loginStatus)) {
      // for backwards compatibility with existing rule
      properties.userId = loginStatus?.userId // the memberId
    }

    if (isFederal(selectedSite)) {
      // for backwards compatibility with existing rule
      properties.key = 'fed_anonymous'
    }

    if (isEU(selectedSite)) {
      // for backwards compatibility with existing rule
      properties.key = 'eu_anonymous'
    }

    return appendPropertiesToUserContext(gonfalonContext, properties)
  }, [gonfalonContext, selectedSite, loginStatus])

  // Here, we take the context and send it off for hashing
  // to gonfalon to get the secureModeHash which is required for flag evals against catfood
  const { config } = useInitGonfalonSecureMode({
    application: selectedSite,
    context,
    enabled: !!context,
  })

  const loadAndSetLoginStatus = () => {
    // when loginStatus is empty, we might need to wait for the loginStatus to be set
    setTimeout(() => {
      setLoginStatus(getLoginStatusFromStorage())
    }, 5000)
  }

  useEffect(() => {
    if (!loginStatus) {
      loadAndSetLoginStatus()
      return
    }

    // need a config and an ldClient to identify the user
    if (!config || !ldClient) {
      return
    }

    // localStorage can get polluted with the various hash keys, so we clear them out before re-identifying which adds a new one
    clearHashBasedLocalStorage(() => {
      // At this point, we have everything we need for proper flag evals:
      // a context from gonfalon with additional properties, and a secure hash
      ldClient.identify(context, config.secureModeHash)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus, selectedSite, ldClient, config])
}
