import { useEffect, useState } from 'react'

import { ApplicationType } from '../../types/applicationType'
import { initGonfalonSecureMode, SecureModeConfig } from '../../utils/secureMode/initGonfalonSecureMode'
import { GonfalonContext } from '../../utils/secureMode/types'

type UseInitGonfalonSecureModeOptions = {
  application: ApplicationType
  context?: GonfalonContext
  enabled?: boolean
}

export const useInitGonfalonSecureMode = ({
  application,
  context,
  enabled,
}: UseInitGonfalonSecureModeOptions): { config: SecureModeConfig | null } => {
  const [config, setConfig] = useState<SecureModeConfig | null>(null)

  useEffect(() => {
    const abortController = new AbortController()
    const signal = abortController.signal

    const fetchConfig = async () => {
      const response = await initGonfalonSecureMode({ application, context, signal })
      if (response && response.secureModeHash && response.dogfoodContext) {
        setConfig({ secureModeHash: response.secureModeHash, dogfoodContext: response.dogfoodContext })
      }
    }

    if (enabled) {
      fetchConfig()
    }

    return () => {
      abortController.abort()
      setConfig(null)
    }
  }, [context, application, enabled])

  return { config }
}
