import { Fragment, FunctionComponent, MutableRefObject, useEffect, useRef } from 'react'
import { Configure, connectSearchBox, InstantSearch } from 'react-instantsearch-dom'

import { getAlgoliaAnalyticsTags, trackSearch } from '../../utils/analyticsUtils'
import { algoliaIndex } from '../../utils/envUtils'
import { getQueryParams } from '../../utils/queryUtils'
import Icon from '../icon'

import Results from './results'
import searchClient from './searchClient'

type SearchInputProps = {
  currentRefinement: string
  refine(input: string): void
  inputRef: MutableRefObject<HTMLInputElement | null>
  onClearSearch(): void
}

type SearchInputAndResultProps = {
  currentRefinement: string
  refine(input: string): void
}

const SearchInput: FunctionComponent<React.PropsWithChildren<SearchInputProps>> = ({
  currentRefinement,
  refine,
  inputRef,
  onClearSearch,
}) => {
  useEffect(() => {
    const q = getQueryParams(window.location, 'iq')
    inputRef.current.value = q
    refine(q)
  }, [refine, inputRef])

  return (
    <Fragment>
      <div
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: 'white',
          border: '1px solid',
          borderColor: 'borderFieldBase',
          borderRadius: 1,
          height: 4,
          width: '100%',
          backgroundColor: 'grayscaleGray900',
        }}
      >
        <Icon name="search" variant="search" sx={{ fill: 'graySafe' }} />
        <input
          ref={inputRef}
          type="text"
          placeholder={'Find documentation'}
          aria-label={'Find documentation'}
          onChange={event => refine(event.currentTarget.value)}
          sx={{
            border: 0,
            width: '100%',
            outline: 'none',
            fontSize: 4,
            '::placeholder': { fontSize: [4, 3, 4] },
            backgroundColor: 'grayscaleGray900',
            color: 'grayscaleWhite',
          }}
          value={currentRefinement}
        />
        {inputRef.current?.value && (
          <Icon name="window-close" variant="close" onClick={onClearSearch} sx={{ cursor: 'pointer' }} />
        )}
      </div>
    </Fragment>
  )
}

const SearchInputAndResult = ({ currentRefinement, refine }: SearchInputAndResultProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const onClearSearch = () => {
    inputRef.current.value = ''
    refine('')
  }
  return (
    <>
      <SearchInput
        currentRefinement={currentRefinement}
        refine={refine}
        inputRef={inputRef}
        onClearSearch={onClearSearch}
      />
      <Results onClearSearch={onClearSearch} />
    </>
  )
}
const ConnectedSearchInputAndResult = connectSearchBox(SearchInputAndResult)

const Root = () => {
  return (
    <InstantSearch indexName={algoliaIndex} searchClient={searchClient} onSearchStateChange={trackSearch}>
      <Configure clickAnalytics analyticsTags={getAlgoliaAnalyticsTags()} />
      <ConnectedSearchInputAndResult />
    </InstantSearch>
  )
}

export default Root
