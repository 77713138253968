import { IconName } from '../icon'

export enum ExpandCollapseEnum {
  Collapsed,
  Expanded,
}

export interface SideNavItem {
  group?: string
  label: string
  shortLabel?: string
  path: string
  svg?: IconName | string
  flagKey?: string
  items?: Array<SideNavItem>
}

export type TreeNodeExpandCollapseState = Record<string, ExpandCollapseEnum>
